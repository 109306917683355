.container-carditem__link {
  text-decoration: none;
}

.container-carditem__calltoaction:hover>.container-carditem__calltoaction__fecha {
  background-color: var(--primary-color);
  transition: all 0.4s;
}

.container-carditem__calltoaction span:hover {
  background-color: var(--secondary-color);
  transition: all 0.4s;
}

.container-carditem__link:hover>.container-carditem .container-carditem__card .container-carditem__card__contenedor-imagen .container-carditem__card__contenedor-imagen__imagen {
  transform: scale(1.07);
  transition: all 0.4s;
}

.container-carditem {
  display: grid;
  grid-template-columns: 70% auto;
  grid-template-rows: 4.3rem 26.5rem repeat(2, 1.65rem);
  width: 28rem;
}

.container-carditem__card {
  grid-row: 2/4;
  grid-column: 1/3;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 17rem auto;
  box-shadow: 0px 0px 0.5rem rgba(0, 0, 0, 0.25);
  overflow: hidden;
}

.container-carditem__titulo {
  grid-row: 1/2;
  grid-column: 1/3;
  background-color: var(--primary-color);
  font-size: 1.8rem;
  color: #FFF;
  display: flex;
  align-items: center;
  justify-content: center;
}

.millon_title{
  font-size: 3rem;
  background: linear-gradient(90deg, rgba(103,78,184,1) 76%, rgba(70,45,153,1) 100%);
}

.container-carditem__titulo small {
  font-size: 1.8rem;
}

.container-carditem__card__contenedor-imagen {
  overflow: hidden;
  width: 100%;
  height: 100%;
  grid-column: 1/3;
}

.container-carditem__card__contenedor-imagen__imagen {
  grid-row: 1/3;
  grid-column: 1/3;
  transition: all 0.2s;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.container-carditem__card__detalles {
  grid-row: 2/3;
  grid-column: 1/3;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  align-items: center;
  justify-content: center;
  padding-bottom: 1.65rem;
}

.container-carditem__card__precio {
  font-weight: 700;
  font-size: 3rem;
  text-align: center;
  color: var(--secondary-color);
}

.container-carditem__card__caracteristicas {
  font-weight: 400;
  font-size: 1.62rem;
  text-align: center;
  color: var(--secondary-color);
}

.container-carditem__calltoaction {
  grid-row: 3/5;
  grid-column: 1/3;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

.container-carditem__calltoaction span {
  background-color: var(--call-to-action-color);
  border-radius: 0.38rem;
  padding: 0.4rem 1.6rem;
  padding-left: 2rem;
  color: #FFF;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 17.3682px;
  line-height: 26px;
  text-align: center;
  transition: all 0.2s;
}

.container-carditem__calltoaction__fecha {
  width: 2rem;
  height: 2rem;
  background-color: var(--secondary-color);
  border-radius: 50ch;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: -1rem;
  z-index: 1;
  transition: all 0.4s;
}

.container-carditem__card__detalles__ambientes {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  justify-items: center;
  column-gap: 12px;
  width: 100%;
  margin-top: .8rem;
  padding: 0 1.8rem;
}

.container-carditem__card__detalles__ambientes__item {
  display: flex;
  column-gap: 7px;
}

.container-carditem__card__detalles__ambientes__item span {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #022C44;
}

.container-carditem__card__detalles__ambientes__info-precio span:last-of-type {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  color: #022C44;
}

.container-carditem__card__detalles__ambientes__info-precio select {
  border: none;
  border: 1px solid #022C44;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #022C44;
  width: 100%;
}

.precio_millon{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  line-height: 0.8;
  position: relative;
  margin: 5px 0;
}

.precio_millon_label{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 3px;
}

.precio_millon_plus{
  color: var(--call-to-action-color);
  font-size: 30px;
  font-weight: 600;
}

.precio_millon_paralelogram{
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0070b3;
  transform: skew(-5deg);
  padding:4px 5px;
}

.precio_millon_paralelogram p{
  font-size: 11px;
  font-weight: 600;
  color: white;
}

@media screen and (max-width: 1512px) {
  .container-carditem {
    display: grid;
    grid-template-columns: 70% auto;
    grid-template-rows: 4.3rem clamp(20rem, 17vw, 28rem) repeat(2, 1.65rem);
    ;
    width: 100%;
  }

  .container-carditem__card {
    grid-template-rows: clamp(11rem, 10.8vw, 17rem) 10.4rem;
  }

  .container-carditem__card__precio {
    font-size: clamp(2.3rem, 2vw, 2.7rem);
  }

  .container-carditem__card__caracteristicas {
    font-size: clamp(1.4rem, 1.2vw, 1.62rem);
  }

  .container-carditem__titulo small,
  .container-carditem__titulo strong {
    font-size: clamp(1.4rem, 1vw, 1.8rem);
  }

  .container-carditem__calltoaction span {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 1024px) {
  .container-carditem {
    display: grid;
    grid-template-columns: 70% auto;
    grid-template-rows: 4.3rem 25.6rem repeat(2, 1.65rem);
    width: 28rem;
  }

  .container-carditem__card {
    grid-row: 2/4;
    grid-column: 1/3;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: 17rem 10.4rem;
    box-shadow: 0px 0px 0.5rem rgba(0, 0, 0, 0.25);
    overflow: hidden;
  }

  .container-carditem__card__precio {
    font-weight: 700;
    font-size: 2.7rem;
    text-align: center;
    color: var(--secondary-color);
  }

  .container-carditem__card__caracteristicas {
    font-weight: 400;
    font-size: 1.62rem;
    text-align: center;
    color: var(--secondary-color);
  }

  .container-carditem__titulo small,
  .container-carditem__titulo strong {
    font-size: 1.8rem;
  }

  .container-carditem__calltoaction span {
    font-size: 1.8rem;
  }
}