:root {
  --primary-color: #0070B3;
  --secondary-color: #022C44;
  --call-to-action-color: #21C0E7;
  --promo-color: #7959D8;
  --promo-color-secondary: #21C0E7
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Poppins', sans-serif;
}

html {
  font-size: 62.5%;
  /*1rem = 10px*/
}

h1,
h2,
h3,
h4,
h5,
h6,
ol,
li,
p {
  color: var(--secondary-color);
}

ol li {
  font-weight: 400;
  font-size: 19.3447px;
  line-height: 29px;
}

::selection {
  background-color: #21bfe7b4;
  color: #fff;
}

::-moz-selection {
  background-color: #21bfe7b4;
  color: #fff;
}

body {
  scrollbar-width: thin;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Works on Chrome, Edge, and Safari */
body::-webkit-scrollbar {
  width: 16px;
}

body::-webkit-scrollbar-track {
  background: #e4e4e4;
}

body::-webkit-scrollbar-thumb {
  background-color: #8e979c;

  border-radius: 20px;
  border: 4px solid #e4e4e4;
}

body::-webkit-scrollbar-thumb:hover {
  background-color: #6b7c85;
}

body::-webkit-scrollbar-thumb:active {
  background-color: #264958;
}