.section-home {
  padding-top: 10rem;
  padding-bottom: 8rem;
}

.promo1 {
  margin-top: 12.5rem;
}

.header-section {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 20.2rem;
}

.header-section__logo {
  grid-column: 1/2;
  grid-row: 1/2;
  filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.25));
}

.header-section__logo__fondo {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-section__logo__logo {
  z-index: 2;
  justify-self: center;
  align-self: center;
}

.header-section__logo__fondo {
  background: url("/public/images/fondo-promos.webp");
  background-position-y: 500px;
  position: relative;
  clip-path: polygon(0 1%, 100% 0, 86% 100%, 0% 100%);
  grid-column: 1/2;
  grid-row: 1/2;
}

.header-section__logo__filtro {
  background: rgba(0, 118, 186, 0.6);
  z-index: -1;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.header-section__leyenda {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row: 1/2;
  grid-column: 1/3;
  align-self: flex-end;
  height: 85%;
  width: 100%;
  background-color: var(--primary-color);
}

.millon_degradado{
  background: linear-gradient(90deg, rgba(2,44,68,1) 31%, rgba(103,78,184,1) 100%);
}

.millon_degradado p{
  width: 90%!important;
}

.header-section__leyenda p {
  grid-column: 2/3;
  font-weight: 700;
  font-size: 3rem;
  line-height: 4.5rem;
  color: #FFF;
  display: flex;
  align-items: center;
  width: 60%;
}

.container-grid-cards {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-items: center;
}

.container-comments {
  display: flex;
  flex-direction: column;
  margin-top: 14.4rem;
  margin-bottom: 15.6rem;
}

.container-comments__background {
  background: url("/public/images/fondo-comments.webp");
  background-repeat: no-repeat;
  background-size: cover;
  height: 37.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container-comments__grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
}

.container-comments__grid-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-items: center;
}

.container-comments__grid-1 {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  justify-items: center;
}

.container-comments__grid .container-comment-card:nth-child(1),
.container-comments__grid .container-comment-card:nth-child(3) {
  transform: scale(0.7);
}

.container-comments__borde {
  width: 67.1rem;
  height: 3.6rem;
  clip-path: polygon(0 0, 100% 0, 91% 100%, 0% 100%);
  background: #0070B3;
}

.borde2 {
  align-self: flex-end;
  transform: rotate(180deg);
}

.enunciado-section_millon{
  background: var(--call-to-action-color);
  width: 75rem;
  height: 8.8rem;
  padding: 1.5rem 0rem;
  margin: 8.5rem auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.enunciado-section_millon::before{
  position: absolute;
  content: '';
  width: 75rem;
  height: 8.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(90deg, rgba(2,44,68,1) 31%, rgba(103,78,184,1) 100%);
  z-index: -1;
  top:-10px;
  left: 10px;
}

.enunciado-section_millon p {
  font-weight: 700;
  font-size: 30px;
  color: #FFF;
  text-align: center;
  text-shadow: 1px 1px 20px rgba(0, 0, 0, 0.7);
}

.enunciado-section {
  background: #0070B3;
  width: 75rem;
  height: 8.8rem;
  padding: 1.5rem 5.6rem;
  box-shadow: 1.8rem -1.9rem 0rem 0rem rgba(2, 44, 68, 1);
  -webkit-box-shadow: 1.8rem -1.9rem 0rem 0rem rgba(2, 44, 68, 1);
  -moz-box-shadow: 1.8rem -1.9rem 0rem 0rem rgba(2, 44, 68, 1);
  margin: auto;
  margin: 8.5rem auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.enunciado-section p {
  font-weight: 400;
  font-size: 2rem;
  line-height: 3rem;
  color: #FFF;
  text-align: center;
}

.contacto-section {
  margin-top: 15rem;
}

@media screen and (max-width:1512px) {
  .container-grid-cards {
    padding: 1.2rem 0.3rem;
    column-gap: 1.5rem;
  }

  .header-section__leyenda p {
    font-size: clamp(2.6rem, 2vw, 3rem);
    line-height: normal;
    width: 85%;
  }

  .header-section__logo__logo {
    width: clamp(36rem, 34vw, 44.7rem);
  }
}

@media screen and (min-width:1024px){
  .logo_modelos_millon{
    width: 100%;
    height: 100%;
  }
}

@media screen and (max-width:1024px) {
  .container-grid-cards {
    overflow-x: scroll;
    column-gap: 2rem;
    padding: 2rem 0.3rem;
  }

  .header-section {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    position: relative;
  }

  .header-section__logo {
    position: absolute;
    height: 14rem;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.2));
    top: -8rem;
    width: 80%;
  }

  .header-section__logo__fondo {
    clip-path: polygon(0 1%, 100% 0, 96% 100%, 0% 100%);
  }

  .header-section__logo__logo {
    max-width: 75%;
  }

  .millon_degradado{
    grid-template-rows: 3rem 1fr!important;
    padding: 2.5rem 0rem 2.5rem 2.5rem !important;
    text-align: center;
  }

  .millon_degradado p{
    font-size: 15px!important;
  }

  .logo_modelos_millon_contenedor{
    position: absolute;
    top: -4rem;
    width: 100%;
    height: fit-content;
  }


  .header-section__leyenda {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 5.8rem 1fr;
    grid-row: 2/3;
    grid-column: 1/3;
    height: 100%;
    width: 100%;
    padding: 2.5rem 2rem;
    background-color: var(--primary-color);
  }

  .header-section__leyenda p {
    width: auto;
    grid-row: 2/3;
    grid-column: 1/3;
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 2.4rem;
  }

  .enunciado-section_millon{
    background: var(--call-to-action-color);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 95%;
    height: auto;
    padding: 1.7rem 0.6rem;
    margin: 4rem auto;
  }
  
  .enunciado-section_millon::before{
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(90deg, rgba(2,44,68,1) 31%, rgba(103,78,184,1) 100%);
    z-index: -1;
    top:-10px;
    left:10px;
  }
  
  .enunciado-section_millon p {
    padding: 0;
    font-size: 2rem;
    line-height: 2.3rem;
    text-align: center;
    font-weight: 700;
    text-shadow: 1px 1px 20px rgba(0, 0, 0, 0.7);
  }

  .enunciado-section {
    width: 95%;
    height: auto;
    padding: 1.7rem 0.6rem;
    margin: 4rem auto;
    box-shadow: 0.8rem -0.9rem 0rem 0rem rgba(2, 44, 68, 1);
    -webkit-box-shadow: 0.8rem -0.9rem 0rem 0rem rgba(2, 44, 68, 1);
    -moz-box-shadow: 0.8rem -0.9rem 0rem 0rem rgba(2, 44, 68, 1);
  }

  .enunciado-section p {
    padding: 0;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 2.1rem;
    text-align: center;
  }

  .container-comments {
    margin: 6rem 0;
  }

  .container-comments__grid {
    overflow: scroll;
    padding: 20rem 0;
    column-gap: 1.7rem;
  }

  .container-comments__borde {
    width: 80%;
  }

  .container-comments__grid .container-comment-card:nth-child(1),
  .container-comments__grid .container-comment-card:nth-child(3) {
    transform: none;
  }
}