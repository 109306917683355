.preguntas-frecuentes-header {
  display: grid;
  grid-template-columns: 18% 77%;
  grid-template-rows: repeat(2, auto);
  gap: 5%;
  margin-top: 8rem;
}

.contenedor-icons {
  grid-area: 1 / 1 / 3 / 2;
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  grid-template-columns: 2fr 1fr 2fr;
  padding-top: 2.5rem;
}

.contenedor-icons img {
  width: 100%;
}

.icon-pregunta {
  grid-area: 1 / 1 / 3 / 3;
  transform-origin: bottom left;
  animation: icons-preguntas .4s ease-out;
}

.icon-respuesta {
  grid-area: 2 / 2 / 4 / 4;
  transform-origin: bottom right;
  animation: icons-preguntas .4s ease-out;
}

@keyframes icons-preguntas {
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
}

.preguntas-frecuentes-encabezado {
  grid-area: 1 / 2 / 2 / 3;
}

.preguntas-frecuentes-encabezado h1 {
  font-size: calc(5em + 1vw);
}

.preguntas-frecuentes-descripcion {
  grid-area: 2 / 2 / 3 / 3;
  margin-top: 0.5rem;
}

.preguntas-frecuentes-descripcion p {
  font-size: calc(0.3em + 1vw);
}

.contenedor-video {
  display: flex;
  justify-content: center;
  padding-top: 8rem;
}

.contenedor-video iframe {
  aspect-ratio: 16/9;
}

.preguntas {
  padding-top: 6rem;
  margin-bottom: 14.5rem;
}

.pregunta-input {
  clip: rect(0 0 0 0);
  margin: -1;
  overflow: hidden;
  position: absolute;
  left: -9999px;
  width: 1px;
  height: 1px;
}

.pregunta {
  background: #CCD5DA;
  border-bottom: 1px solid #eee;
  cursor: pointer;
  display: block;
  font-size: 1.1875rem;
  letter-spacing: -.0225rem;
  margin-bottom: 1.6rem;
  padding: 20px;
  position: relative;
  transition: all 0.35s ease;
  font-size: 2.4rem;
}

.pregunta:hover {
  cursor: pointer;
}

.pregunta::before {
  content: "\276f";
  font-size: 18px;
  float: right;
  transition: all 0.15s ease;
  margin-top: 4px;
}

.pregunta-input:checked~.pregunta {
  background: var(--secondary-color);
  color: #fff;
}

.pregunta-input:checked~.pregunta::before {
  transform: rotate(-90deg);
}

.pregunta-input:checked~.pregunta:hover {
  color: #fff;
}

.pregunta-input:checked~.pregunta:hover::before {
  opacity: 1;
}

.pregunta-input:checked~.respuesta {
  max-height: 1000px;
  padding: 15px 0;
  margin-bottom: 20px;
  transition: max-height 1s ease-in, margin .3s ease-in, padding .3s ease-in;
}

.respuesta {
  background: #E6EAED;
  margin-bottom: 25px;
  margin-top: -17px;
  max-height: 0;
  overflow: hidden;
}

.respuesta p,
.respuesta ol li {
  color: #000;
}

.respuesta ol li {
  font-size: 1.9rem;
  line-height: normal;
}

.respuesta p {
  padding: 1rem 2rem;
  font-size: 2rem;
}

.xxxx {
  margin-left: 6rem;
  font-size: 2rem;
}

.preguntas-frecuentes__list-items {
  padding-left: 5.5rem;
}

@media screen and (max-width:1200px) {
  .preguntas-frecuentes-encabezado h1 {
    font-size: calc(4.5em + 1vw);
  }

  .preguntas-frecuentes-descripcion p {
    font-size: 1.6rem;
  }

  .respuesta ol li {
    font-size: 1.4rem;
    line-height: normal;
  }
}

@media screen and (max-width:853px) {
  .preguntas-frecuentes-header {
    grid-template-columns: 23% 70%;
  }

  .contenedor-icons {
    grid-area: 1 / 1 / 2 / 2;
    padding-top: 0;
  }

  .preguntas-frecuentes-encabezado {
    grid-area: 1 / 2 / 2 / 3;
  }

  .preguntas-frecuentes-descripcion {
    grid-area: 2 / 1 / 3 / 3;
    margin-top: 2rem;
  }

  .preguntas-frecuentes-encabezado h1 {
    line-height: 6rem;
  }

  .pregunta {
    font-size: 1.6rem;
  }

  .respuesta p {
    font-size: 1.4rem;
  }

  .xxxx {
    font-size: 1.4rem;
  }

  .contenedor-video {
    padding-top: 4rem;
  }

  .pregunta::before {
    margin-top: 0;
  }
}

@media screen and (max-width:600px) {
  .preguntas-frecuentes-header {
    grid-template-columns: 30% 65%;
    gap: 0 5%;
  }

  .preguntas-frecuentes-encabezado h1 {
    font-size: calc(3em + 1vw);
    line-height: 4rem;
  }
}