.tituloForm {
  text-align: center;
  color: var(--dark-blue);
  display: grid;
  grid-template-rows: auto;
  height: 100%;
}

.blur-overlay .tituloForm h2, .blur-overlay .tituloForm p, .overlay .tituloForm h2, .overlay .tituloForm p{
  color: white;
}

.tituloForm h2 {
  font-size: 5rem;
  align-self: flex-start;
}

.tituloForm p {
  font-weight: 400;
  font-size: 2rem;
  line-height: 3rem;
  text-align: center;

}

.signupFrm {
  display: grid;
  grid-template-columns: 35% 1fr;
  column-gap: 5rem;
  justify-items: center;
  align-items: center;
  margin: 5rem 0;
}

.form {
  background-color: white;
  width: 100%;
  border-radius: 0.8rem;
  padding: 5.4rem 4.7rem;
  padding-bottom: 2.2rem;
  box-shadow: 0 0 1.5rem rgb(92 99 105 / 20%);
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.title {
  font-size: 5rem;
  margin-bottom: 5rem;
}

.inputContainer {
  position: relative;
  height: 5.5rem;
  margin-bottom: 1.7rem;
}

.form-modal .inputContainer {
  height: 4.5rem;
}

.input {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  border: 1px solid #909194;
  border-radius: 0.7rem;
  font-size: 1.6rem;
  padding: 0 2rem;
  outline: none;
  background: none;
  z-index: 1;
  font-family: 'Poppins', sans-serif;
}

::placeholder {
  color: transparent;
}

.label {
  position: absolute;
  top: 1.5rem;
  left: 1.5rem;
  padding: 0 0.4rem;
  background-color: white;
  color: #707275;
  font-size: 1.3rem;
  transition: 0.5s;
  z-index: 0;
}

.grid-container {
  display: grid;
  grid-template-columns: 32% 66%;
  grid-template-rows: 1fr;
  grid-column-gap: 1rem;
}

.submitBtn {
  display: block;
  margin-left: auto;
  padding: 1.2rem 3.6rem;
  border: none;
  background-color: var(--call-to-action-color);
  color: white;
  border-radius: 0.6rem;
  cursor: pointer;
  font-size: 2rem;
  font-weight: 600;
  margin-top: 0.7rem;
  transition: all 0.2s;
}

.submitBtn:hover {
  background-color: var(--secondary-color);
  transition: all 0.4s;
}

.input:focus+.label {
  top: -7px;
  left: 3px;
  z-index: 10;
  font-size: 1.4rem;
  font-weight: 600;
  color: var(--strong-blue);
}

.input:focus {
  border: 0.2rem solid var(--strong-blue);
}

.input:not(:placeholder-shown)+.label {
  top: -7px;
  left: 3px;
  z-index: 10;
  font-size: 1.4rem;
  font-weight: 600;
}

textarea {
  resize: none;
  padding-top: 2rem;
  padding: 2rem !important;
}

.inputConsulta {
  position: relative;
  height: 150px;
  margin-bottom: 1.7rem;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px white inset;
}

.input:focus {
  border: 0.2rem solid var(--primary-color);
}

.signupFrm.hideFormSection {
  display: none;
}

.input-error {
  position: absolute;
  bottom: -20px;
  color: red;
  left: 0;
  font-size: 1.2rem;
}

.woody-contacto {
  width: 30rem;
  justify-self: center;
  align-self: flex-end;
}

@media screen and (max-width: 1350px) {
  .tituloForm h2 {
    font-size: 4rem;
  }

  .tituloForm h2 {
    font-size: 3.6rem;
  }

  .tituloForm p {
    font-size: 2.4rem;
    line-height: normal;
  }

  .form {
    width: 65%;
    padding: 2rem;
  }

  .signupFrm {
    margin: 0;
  }

  .woody-contacto {
    margin: 2rem 0;
  }
}

@media screen and (max-width: 1024px) {
  .signupFrm {
    margin-top: 4.5rem;
    grid-template-columns: 1fr;
    width: 100%;
  }

  .tituloForm {
    width: 100%;
    margin-bottom: 3rem;
  }

  .form {
    width: 100%;
    margin-bottom: 3rem;
  }

  .submitBtn {
    width: 100%;
  }

  .woody-contacto {
    width: 23.2rem;
  }

  .tituloForm p {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 768px) {
  .tituloForm h3 {
    font-size: 2rem;
    padding: 1rem 0;
  }

  .grid-container {
    grid-template-columns: 40% 58%;
  }
}

@media screen and (max-width: 480px) {
  .tituloForm h3 {
    font-size: 1.8rem;
    padding: 0.8rem 0;
  }

  .grid-container {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, 1fr);
  }
}