.politicas-encabezado {
    display: flex;
    justify-content: center;
    align-items: center;
}

.politicas-encabezado h1 {
    font-size: 5rem;
    color: var(--primary-color);
}

.top_pol {
    margin-top: 5rem;
    margin-bottom: 5rem;
}

.politicas__titulo {
    font-size: 2vw;
    margin-top: 25px;
    margin-bottom: 30px;
}

.politicas__enunciado {
    font-size: 20px;
    background-color: #f7f7f7;
    border-radius: 2rem;
    padding: 2rem;
}

.bottom_pol {
    margin-bottom: 10rem;
}

@media screen and (max-width: 1200px){
    .politicas__titulo {
        font-size: 3rem;
    }
}

@media screen and (max-width: 1000px){
    .politicas__titulo {
        font-size: 2.5rem;
        text-align: center;
    }

    .politicas-encabezado h1 {
        text-align: center;
    }
}