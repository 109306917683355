.dropdown {
  background-color: var(--primary-color);
  border-radius: 0.5rem;
  box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08), 0 4px 6px -2px rgba(71, 63, 79, 0.16);
  display: none;
  font-size: 0.875rem;
  left: 10px;
  list-style: none;
  min-width: max-content;
  padding-left: 1rem;
  padding-right: 1rem;
  padding: 0.5rem 1rem;
  position: absolute;
  right: 0;
  z-index: 9999;
}

.subItemAlign {
  align-items: center;
  color: gainsboro;
  display: flex;
}

.dropdown.show {
  display: block;
}

.dropdown .dropdown-submenu {
  left: 100%;
  position: absolute;
  top: -0.7rem;
}

.dropExpand {
  align-items: center;
  display: flex;
  flex-direction: column;
  list-style-image: none;
  list-style-type: none;
  margin-left: 1rem;
}