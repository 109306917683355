.container-comment-card {
  width: 31.3rem;
  height: 20rem;
  padding: 1.8rem 2rem;
  background: #FFFFFF;
  box-shadow: 0px 0px 8.52861px rgba(0, 0, 0, 0.15);
  position: relative;
}

.animation-comment-card {
  animation: enterLeave 5s infinite;
}

@keyframes enterLeave {
  0% {opacity: 0.1; scale: .9; filter: blur(2.5px);}
  10% {opacity: 1; scale: 1; filter: blur(0px);}
  90% {opacity: 1; scale: 1; filter: blur(0px);}
  100% {opacity: 0.1; scale: .9; filter: blur(2.5px);}
}

.container-comment-card__icon {
  position: absolute;
  right: 2rem;
  top: -2.9rem;
}

.container-comment-card__header {
  font-weight: 700;
  font-size: 2.1rem;
  line-height: 122%;
  margin-bottom: .8rem;
}

.container-comment-card__header__puntuacion {
  margin-top: -.8rem;
}

.container-comment-card__header__content p {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 122%;
}